import React, { Component } from 'react';
import {Link} from "react-router-dom"

class Navbar extends Component {
    render() {
        return (
            <nav className="navbar navbar-expand-lg navbar-light fixed-top" style={{backgroundColor : "#1d2935"}}>
                <div className="container-fluid">
                    <Link className="navbar-brand text-white mx-lg-5" to='/'  >Data TransCoder</Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse justify-content-end" id="navbarNavAltMarkup">
                        <div className="navbar-nav">
                            <Link className="nav-link mx-3 text-white" aria-current="page" to="/json_beautify">JsonBeautify</Link>
                            <Link className="nav-link mx-3 text-white" to="/xml_convert">XML to JSON</Link>
                            <Link className="nav-link mx-3 text-white" to="#contactus">About</Link>
                        </div>
                    </div>
                </div>
            </nav>
        );
    }
}

export default Navbar;