import React, { Component } from 'react';

class RightTextArea extends Component {
    render() {
        return (
            <div id = 'rightResult' style={{backgroundColor : "gray" , height : "600px" , wordWrap : "break-word" , overflowY: 'auto'}}>
                <div className='ace-main' id = "rightResultMainDiv" style={{whiteSpace : 'break-spaces'}}></div>
            </div>
        );
    }
}

export default RightTextArea;