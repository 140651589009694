import React, { Component,Fragment } from 'react';
import './App.css'
import Navbar from "./components/Navbar"
import Footer from './components/Footer';
import {  Routes , Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import XMLPage from './pages/XMLPage';
import JsonPage from './pages/JsonPage';


class App extends Component {
  render() {
    return (
      <Fragment>
        <Navbar/>
        <Routes>
          <Route path='/' Component={HomePage} />
          <Route path='/xml_convert' Component={XMLPage} />
          <Route path='/json_beautify' Component={JsonPage} />
        </Routes>
        <Footer/>
      </Fragment>
    );
  }
}

export default App;