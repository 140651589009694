import React, { Component } from 'react';

class Footer extends Component {
    render() {
        return (
            <div className='container-fluid p-4 text-white' style={{backgroundColor : '#1d2935'}}>
                <div className='row'>
                    <div className='col-6'>
                        Copyright @ Data TransCoder 2024
                    </div>
                </div>
            </div>
        );
    }
}

export default Footer;