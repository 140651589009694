
import beautify from 'json-beautify';
import jsonDisplay from 'json-display';
import { addSlashes, removeSlashes } from 'slashes';

const recursion = (arr , index , stack , result)=>
{
    if (index > arr.length)
    {
        return 
    }
    let res = null
    let strLen = arr[index].length
    let val = arr[index][strLen-1]
    let spaces = remove_space(arr[index])
    let space = spaces[0]
    let updateString = spaces[1]

    let bracket = ""
    result = result +  `<span class = 'ace-line'>${index}</span>`
    for(let i = 0 ; i < space ; i++)
    {
        result = result +  `<span class='dot_image' >    </span>`
    }
    result = result + `<span >${updateString}</span>`
    
    let isOpen = false
    if(val == '[' || val == '{')
    {
        result = result + `<div class = 'ace-body'><button onclick = "(function(e){
            const entries = e.target.nextSibling;
            if(entries.style.display === 'block' || entries.style.display === ''){
                entries.style.display = 'none';
                e.target.parentElement.style.display = 'inline-block'
                e.target.parentElement.nextSibling.style.display = 'none';
                e.target.innerText = '&#9658;';
                let next = e.target.parentElement.nextSibling.nextSibling
                while(next)
                {
                    if(next.classList.contains('dot_image'))
                    {
                        next.style.display = 'none'
                        next = next.nextSibling
                    }
                    else
                    {
                        break;
                    }
                }
            }else {
                entries.style.display = 'block';
                e.target.parentElement.style.display = 'block'
                e.target.parentElement.nextSibling.style.display = 'block';
                e.target.innerText = '&#9660;'; 
                let next = e.target.parentElement.nextSibling.nextSibling
                while(next)
                {
                    if(next.classList.contains('dot_image'))
                    {
                        next.style.display = 'inline'
                        next = next.nextSibling
                    }
                    else
                    {
                        break;
                    }
                }
            }
            return false;
            })(arguments[0]);return false;" class = 'ace-button'>&#9660;</button><div><div>`
        stack.push(val)
        bracket = val

        let result1 = recursion(arr , index + 1 , stack , "")
        res = result1[0]
        index = result1[1]
        isOpen = result1[2]
        result = result + `<div style = "">` +  res 
        while (isOpen)
        {
            let result2 = recursion(arr , index + 1 , stack , "")
            res = result2[0]
            index = result2[1]
            isOpen = result2[2]
            result = result + res
        }
        let endRes = recursion(arr , index + 1 , stack , "")
        result = result + '</div></div></div></div>'
        result = result + endRes[0]
        isOpen = endRes[2]
        index = endRes[1]

        if(isOpen)
        {   
            result = result + `</div></div><div><div style = "">`
            
            let result2 = recursion(arr , index + 1 , stack , "")
            return [result + result2[0] , result2[1] , result2[2]]
        }
        return [result , index  , false]
    }   
    else if(val == ']' || val == '}')
    {
        let result1 = `<span class = 'ace-line'>${index}</span>`
        for(let i = 0 ; i < space ; i++)
        {
            result1 = result1 +  `<span class='dot_image' >    </span>`
        }
        result1 = result1 + `<span >${updateString}</span>`
        return [ result1 , index , false ]
    }
    {
        let result1 = `<span class = 'ace-line'>${index}</span>`
        for(let i = 0 ; i < space ; i++)
        {
            result1 = result1 +  `<span class='dot_image' >    </span>`
        }
        result1 = result1 + `<span >${updateString}</span>`
        let body = result1
        if(val === ',')
        {
            body = body + '<br/>'
            return [body , index , true]
        }
        else
        {
            return [body , index , false]
        }   
    }
}

const remove_space = (string) =>{
    let count = 0;
    let total = 0;
    for(let i = 0 ; i < string.length ; i++)
    {
        if(string[i] == " ")
        {
            if(count < 4)
            {
                count = count + 1
            }
            else
            {
                count = 0
                total = total + 1
            }
        }
        else
        {
            return [total , string.slice(total * 4 )]
        }
    }
    return [total , string]
}

const jsonToHTML = (jsonFormatRightData) =>{
    let stringLength = jsonFormatRightData.split("\n")
    let recResult = recursion(stringLength , 0 , [] , "")[0]
    return recResult
}

export const formatBeautyHandler = (textContent) =>{
    try {
        let jsonLeftData = JSON.parse(textContent);
        let jsonFormatRightData = beautify(jsonLeftData , null , 4)
        return jsonToHTML(jsonFormatRightData)
    } catch (error) {
        return jsonToHTML(error.message)
    }
}

export const json_escape = (textContent) =>{
    return addSlashes(textContent)
}

export const json_unescape = (textContent) =>{
    return removeSlashes(textContent)
}

export const prettyDispalyHandler = (textContent) =>{

    try {
        let data = jsonDisplay(JSON.parse(textContent))
        return data
    } catch (error) {
        return jsonToHTML(error.message)
    }
    
}


