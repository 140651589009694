const { XMLParser, XMLBuilder} = require("fast-xml-parser");
const XmlBeautify = require("xml-beautify")

export const XMLToJSONHandler = (textContent) =>{
    
    try {
        const options = {
            ignoreAttributes: false,
            attributeNamePrefix : "-",
            ignoreDeclaration: true,
            allowBooleanAttributes: true,
            ignorePiTags: true,
            removeNSPrefix: true,
            cdataPropName: "__cdata"
        };
        const parser = new XMLParser(options);
        let xmlJson = parser.parse(textContent);
        return xmlJson
    } catch (error) {
        return error.message
    }
}

export const JSONToXMLHandler = (textContent) =>{
    try {
        const options = {
            ignoreAttributes: false,
            attributeNamePrefix : "-",
            ignoreDeclaration: true,
            allowBooleanAttributes: true,
            ignorePiTags: true,
            removeNSPrefix: true,
            cdataPropName: "__cdata"
        };
        let JsonParse = JSON.parse(textContent)
        let jsObj = {
            "?xml": {
              "-version": "1.0",
              "-encoding": "UTF-8"
            },
            ...JsonParse
          };
        
        const builder = new XMLBuilder(options);
        const xmlContent = builder.build(jsObj);
        var prettyXmlText = new XmlBeautify().beautify(xmlContent, 
            {indent: "    ",useSelfClosingElement: true});
        let prettyXmlTextList = prettyXmlText.split('\n')
        return prettyXmlTextList
    } catch (error) {
        return error.message
    }
}

