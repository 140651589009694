import React, { Component, Fragment } from 'react';
import LeftTextArea from '../components/LeftTextArea';
import RightTextArea from '../components/RightTextArea';
import { formatBeautyHandler, prettyDispalyHandler , json_escape } from '../services/json_beautify_converter';

class JsonPage extends Component {

    state = {
        leftTextData : "",
        formatError : "",
    }

    formatBeautyMapped = (e) =>{
        let jsonLeftData = document.getElementById('leftText').textContent;
        if (jsonLeftData != '')
        {
            let beautifyResult = formatBeautyHandler(jsonLeftData);
            let mainDiv = document.getElementById("rightResultMainDiv")
            mainDiv.innerHTML = beautifyResult
            mainDiv.style.whiteSpace = 'break-spaces'
            mainDiv.style.overflow = 'auto'
        }
    }

    prettyDispalyMapped = (e) =>{
        let jsonLeftData = document.getElementById('leftText').textContent;
        let prettyJsonResult = prettyDispalyHandler(jsonLeftData)
        let div = document.getElementById("rightResultMainDiv")
        div.style.whiteSpace = 'break-spaces'
        div.style.overflow = 'hidden'
        div.innerHTML = ""
        if(typeof(prettyJsonResult) === 'string')
        {
            div.innerHTML = prettyJsonResult
        }
        else
        {
            div.appendChild(prettyJsonResult)
            div.firstElementChild.style.height = '100%'
        }
    }

    jsonStringify = (e) =>{
        let jsonLeftData = document.getElementById('leftText').textContent;
        let jsonEscape = json_escape(jsonLeftData)
        let div = document.getElementById("rightResultMainDiv")
        div.style.overflow = 'auto'
        div.innerHTML = jsonEscape
    }

    render() {

        return (
            <Fragment>
                <section>
                    <div className='container-fluid' >
                        <div className='row' style={{paddingTop : "200px", paddingBottom : "200px", backgroundColor : "darkcyan"}} >
                            <div className='col-lg-5 col-12'>
                                <LeftTextArea/>
                            </div>
                            <div className='col-lg-2 col-12 align-items-center' style={{textAlign : "center" , display : "inline-grid"}}>
                                <div className='row'>
                                    <div className='col-lg-12 col-12'>
                                        <button className='btn my-3 px-4 py-2 formatter_btn' style={{border : "solid 2px white"}} onClick = {this.formatBeautyMapped}>Format Beauty</button>
                                    </div>
                                    <div className='col-lg-12 col-12'>
                                        <button className='btn my-3 px-4 py-2 formatter_btn' style={{border : "solid 2px white"}} onClick = {this.prettyDispalyMapped}>Json Display</button>
                                    </div>
                                </div>  
                            </div>
                            <div className='col-lg-5 col-12'>
                                <RightTextArea/>
                            </div>
                        </div>
                    </div>
                </section>
                <section id = "about">
                    <div className='container-fluid'>
                        <div className='row' style={{backgroundColor : "#035d95" , paddingTop : "50px" , paddingBottom : "200px" , color : "white" , paddingLeft : "200px" , paddingRight : "200px"}}>
                            <div className='col-12 text-white' style={{textAlign : "center" , fontSize : "32px", fontWeight : "bold"}}>
                                JSON Formatter
                            </div>
                            <div className='col-6'>
                                <div className='row'>
                                    <div className='col-12 my-3' style={{fontSize : "20px"}}>
                                        JSON stands for JavaScript Object Notation. JSON is a lightweight format for storing and transporting data.<br/>JSON Formatter and JSON Validator help to auto format JSON and validate your JSON text. It also provides a tree view that helps to navigate your formatted JSON data.
                                    </div>
                                    <div className='col-12'>
                                        <ul>
                                            <li>It helps to validate JSON online with Error Messages.</li>
                                            <li>It's the only JSON tool that shows the image on hover on Image URL in a tree view.</li>
                                            <li>Supports Printing of JSON Data.</li>
                                            <li>Supports JSON Graph View of JSON String which works as JSON debugger or corrector and can format Array and Object.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className='col-6'>
                                <div className='row'>
                                    <div className='col-12 my-3' style={{fontSize : "20px"}}>
                                        Online JSON Formatter and Online JSON Validator provide JSON converter tools to convert JSON to XML, JSON to CSV, and JSON to YAML also JSON Editor, JSONLint, JSON Checker, and JSON Cleaner.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Fragment>
        );
    }
}

export default JsonPage;