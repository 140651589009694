import React, { Component } from 'react';
// import TextSearchBar from './TextSearchBar';

class LeftTextArea extends Component {

    keyDownHandler = (event) =>{
        if (event.ctrlKey && event.key === 'f') { 
            event.preventDefault(); // Prevent the default browser find functionality 
            const searchField = document.getElementById('LeftSearchBar'); // Replace 'searchField' with the ID of your search field 
            searchField.style.display = 'block' // Focus on the search field 
          } 
    }

    render() {
        return (
            <div className='row' style={{paddingLeft : 'inherit' , paddingRight : 'inherit'}}>
                <div className='col-1 p-0' style={{backgroundColor : 'gray' , }}>
                </div>
                <div className='col-11 p-0' style={{position : "relative"}}>
                    <div id = "LeftSearchBar" className='col-8' style={{position : "absolute" , right : 0 , zIndex : 1 , display : 'none'}}>
                        {/* <TextSearchBar/> */}
                    </div>
                    <div  contentEditable = 'plaintext-only' id = 'leftText' style={{height : "600px" , width : "100%" , outline : "0px" , backgroundColor : "white" , overflow : "auto" , fontSize : "14px" , fontWeight : "500" , position : 'relative'}}>
                    </div>
                </div>
            </div>
        );
    }
}

export default LeftTextArea;