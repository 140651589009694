import React, { Component } from 'react';
import LeftTextArea from '../components/LeftTextArea';
import RightTextArea from '../components/RightTextArea';
import { formatBeautyHandler, prettyDispalyHandler } from '../services/json_beautify_converter';
import { XMLToJSONHandler , JSONToXMLHandler } from '../services/json_xml_converter';


class XMLPage extends Component {

    state = {
        leftTextData : "",
        formatError : "",
    }

    handleChange = (e) =>{
        let text = e.target.value
        this.setState({leftTextData : text})
    }

    formatBeautyMapped = (e) =>{
        let jsonLeftData = document.getElementById('leftText').textContent;
        let beautifyResult = formatBeautyHandler(jsonLeftData);
        let mainDiv = document.getElementById("rightResultMainDiv")
        mainDiv.innerHTML = beautifyResult
        mainDiv.style.whiteSpace = 'break-spaces'
        mainDiv.style.overflow = 'auto'
    }

    prettyDispalyMapped = (e) =>{
        let jsonLeftData = document.getElementById('leftText').textContent;
        let prettyJsonResult = prettyDispalyHandler(jsonLeftData)
        let div = document.getElementById("rightResultMainDiv")
            div.innerHTML = ""
            div.appendChild(prettyJsonResult)
    }

    XMLToJsonMapped = (e) => {
        let jsonLeftData = document.getElementById('leftText').textContent
        let xmlJson = XMLToJSONHandler(jsonLeftData);
        let beautifyResult = formatBeautyHandler(JSON.stringify(xmlJson));
        let mainDiv = document.getElementById("rightResultMainDiv")
        mainDiv.innerHTML = beautifyResult
        mainDiv.style.whiteSpace = 'break-spaces'
        mainDiv.style.overflow = 'auto'
    }

    JSONToXMLMapped = (e) =>{
        let jsonLeftData = document.getElementById('leftText').textContent
        let xmlContent = JSONToXMLHandler(jsonLeftData)
        document.getElementById("rightResultMainDiv").style.whiteSpace = 'break-spaces'
        document.getElementById("rightResultMainDiv").textContent = xmlContent
    }
    

    render() {

        return (
            <div className='container-fluid' style={{paddingTop : "200px", paddingBottom : "200px", backgroundColor : "darkcyan"}}>
                <div className='row'>
                    <div className='col-lg-5 col-12'>
                        <LeftTextArea/>
                    </div>
                    <div className='col-lg-2 col-12 align-items-center' style={{textAlign : "center" , display : "inline-grid"}}>
                        <div className='row'>
                            <div className='col-lg-12 col-12'>
                                <button className='btn my-3 px-4 py-2 formatter_btn' style={{border : "solid 2px white"}} onClick = {this.XMLToJsonMapped}>XML To JSON</button>
                            </div>
                            <div className='col-lg-12 col-12'>
                                <button className='btn my-3 px-4 py-2 formatter_btn' style={{border : "solid 2px white"}} onClick = {this.JSONToXMLMapped}>Json To XML</button>
                            </div>
                        </div>  
                    </div>
                    <div className='col-lg-5 col-12'>
                        <RightTextArea/>
                    </div>
                </div>
            </div>
        );
    }
}

export default XMLPage;